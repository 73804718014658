<template>
	<viewer-layout
		ref="layout"
		:allow-related-studies="true"
		:primary-toolbar-config="primaryToolbarConfig"
    :clinic-code="clinicCode"
		@download-study="downloadStudies"
		@burn-in-annotations="burnInAnnotations"
		@drop-study-report="onDropStudyReport"
	>
		<template #primary-buttons-left>
			<ast-toolbar-button class="back-btn" icon="close" title="Close" @click.native="goBack" />
		</template>
		<template #primary-buttons-right>
			<ast-toolbar-button
				v-if="activeReportId"
				:loading="isAddingComment"
				:icon="imageComment ? 'checkbox-marked' : 'edit'"
				:label="imageComment ? 'Added to report' : 'Add to report'"
				class="label-right"
				:class="imageComment && 'added-to-report'"
				:disabled="!isImageCommentAllowed || isRefreshingReport || isAddingComment"
				@click.native="updateImageComment"
			/>
		</template>

		<template #panels>
			<modal-drawer
				v-if="isImageCommentAllowed && isImageCommentOpen"
				title="Image Comment"
				from-right
				@close="isImageCommentOpen = false"
			>
				<ast-image-comment-form
					:report-id="activeReportId"
					:image-id="activeImage ? activeImage.imageId : undefined"
					:series-id="activeSeries ? activeSeries.originalSeriesId || activeSeries.seriesId : undefined"
					:preview-image-url="previewImageUrl"
					:image-comment="imageComment"
					@update-comment="refreshReport"
				/>
			</modal-drawer>
		</template>
	</viewer-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { eventBus } from '@services/eventBus'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { findImageUrlFromImage } from '@utils/urlUtility'
import ViewerLayout from '@router/layouts/Viewer'
import AstImageCommentForm from '@components/view/ViewerImageCommentForm'
import ModalDrawer from '@components/ModalDrawer'
import AstToolbarButton from '@components/ToolbarButton'
import reportService from '@services/reportService'
import difference from 'lodash/difference'
import arrayToList from '@utils/arrayToList'
import listToArray from '@utils/listToArray'
import { openViewerStudyDownloadModal } from '../../components/view/ViewerStudyDownloadModal'
import { omniDesktop } from '@/electron/omniDesktop'
import { imageLoader } from '@/imageLoader'
import wsData from '@services/wsData'
import api from '@services/api'
import keyboard from '@services/keyboard'

let isUserDataLoadedUnwatch

export default {
	name: 'Viewer',
	components: {
		ViewerLayout,
		AstImageCommentForm,
		ModalDrawer,
		AstToolbarButton,
	},
	props: {
		clinicCode: {
			type: String,
			default: undefined,
			required: false,
		},
		studyIds: {
			type: Array,
			default: () => [],
		},
		reportIds: {
			type: Array,
			default: () => [],
		},
		initialSeriesIds: {
			type: Array,
			default: () => [],
		},
		initialSeriesId: {
			type: String,
			default: undefined,
		},
		initialImageId: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			primaryToolbarConfig: null,
			isImageCommentOpen: false,
			report: null,
			isAddingComment: false,
			isRefreshingReport: false,
			fromRoutePath: '',
			fallbackRoutePath: '',
		}
	},
	computed: {
		...mapState({
			claims: state => state.auth.claims,
			isUserDataLoaded: state => state.isUserDataLoaded,
			studies: state => state.viewer.studies,
			studiesNotFound: state => state.viewer.studiesNotFound,
			shouldOpenInNewWindow: state => state.windows.openStudiesInNewWindow,
		}),
		...mapGetters([
			'activeImage',
			'activeSeries',
			'activeReportId',
			'loadedStudyIds',
			'loadedReportIds',
			'mprActive',
			'isAuthenticated',
		]),
		previewImageUrl() {
			if (!this.activeImage || !this.activeSeries) return ''
			const result = findImageUrlFromImage(this.activeImage, this.activeSeries)
			return result || ''
		},
		imageComment() {
			if (!this.activeImage || !this.report || !this.report.imageComments || this.mprActive) return
			return this.report.imageComments.find(c => c.imageId === this.activeImage.imageId)
		},
		isImageCommentAllowed() {
			if (!this.report) return false
			// No comments on complete report
			if (this.report.isComplete) return false
			// Allow for all users on std reports
			if (!this.report.isTeleconsultation) return true
			// For teleconsultation, user must be a consultant
			if (!this.claims.isConsultantUser) return false
			if (!this.report.groupConsultantId) return true
			// If owned by a group, user must have claimed report
			return this.claims.userId === this.report.lockedByUserId
		},
		isPartnerViewer() {
			return this.$route.name === 'partner-study-viewer'
		},
		allowHangingProtocol() {
			return this.$route.meta.allowHangingProtocol
		},
	},
	watch: {
		studiesNotFound: {
			handler() {
				this.promptToAddStudiesToClinic()
			},
			immediate: true,
			deep: true,
		},
		studies: {
			handler(val, oldValue) {
				wsData.unsubscribeByCallback(this.refreshStudy)
				val.forEach(s => wsData.subscribe(s.studyId, this.refreshStudy))

				// Do some cloning or else the router won't pick up the change
				const { path, query } = this.$route
				const route = JSON.parse(JSON.stringify({ path, query }))
				// Assign list of studies and reports to query
				route.query.studyIds = arrayToList(this.loadedStudyIds)
				route.query.reportIds = arrayToList(this.loadedReportIds)
				// Omit query properties from url if needed
				if (!route.query.studyIds) delete route.query.studyIds
				if (!route.query.reportIds) delete route.query.reportIds
				// Replace the route
				this.$router.replace(route)
				// Prefetch images for new studies
				imageLoader.prefetch()
			},
		},
		async $route() {
			// Generate list of studies and reports that need to be removed and loaded
			// Doing a full clear and reload has the negative side effects of having to
			// unnecessarily reload image data as well as losing the current canvas state
			const diff = (from, to) => [...new Set(difference(from, to))]
			const removeStudies = diff(this.loadedStudyIds, this.studyIds)
			const addStudies = diff(this.studyIds, this.loadedStudyIds)
			const removeReports = diff(this.loadedReportIds, this.reportIds)
			const addReports = diff(this.reportIds, this.loadedReportIds)
			// Remove studies and reports
			removeStudies.forEach(studyId => this.$store.commit('REMOVE_STUDY', studyId))
			removeReports.forEach(reportId => this.$store.commit('REMOVE_REPORT_STUDIES', reportId))
			// Load studies and reports
			await this.loadData(addStudies, addReports)
		},
		initialSeriesId(seriesId) {
			// change active series due to study list thumbnail click (browser)
			if (seriesId) this.$store.dispatch('switchSeries', { seriesId })
		},
		initialSeriesIds(seriesIds, previousSeriesIds) {
			// change active series due to study list thumbnail click (electron)
			if (JSON.stringify(seriesIds) === JSON.stringify(previousSeriesIds)) return
			if (seriesIds && seriesIds.length) this.$store.dispatch('switchSeries', { seriesId: seriesIds[0] })
		},
		async activeReportId() {
			await this.refreshReport()
		},
		isImageCommentOpen(isOpen) {
			if (isOpen) keyboard.stopListening()
			else keyboard.startListening()
		},
	},
	created() {
		this.setupPrimaryToolbarConfig()
		this.isNewWindow = !!window.opener && !this.isMobileOS
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			// Save the route path we're coming from,
			// just in case we need to fall back to it on close
			if (from && from.path) {
				if (from.name === 'login') vm.fromRoutePath = '/'
				else vm.fromRoutePath = from.path
			}
		})
	},
	beforeRouteUpdate(to, from, next) {
		if (to.path === '/viewer/') {
			this.onViewerClosed()
		}
		next()
	},
	async mounted() {
		if (!this.isPartnerViewer) {
			// Study/Report setup
			window.isShowingStudyOrReport = ({ studyId, reportId }) =>
				this.studyIds.includes(studyId) || this.reportIds.includes(reportId)
			window.onAddToViewer = object => this.onAddToViewer(null, object)
			eventBus.on('openViewerReportComment', this.onOpenReportCommentAction)
			eventBus.on(eventBus.type.REPORT_COMPLETED, this.onReportCompleted)
			eventBus.on(eventBus.type.CLOSE, this.onExternalWindowClosed)
			// Electron setup
			if (omniDesktop.isConnected) {
				omniDesktop.on('addToViewer', this.onAddToViewer)
				omniDesktop.on('resetViewer', this.onResetViewer)
			}
			// Viewer setup
			window.addEventListener('beforeunload', this.onViewerClosed)
		}
		// Load study data, ensuring user settings, etc. have been loaded first
		if (this.isUserDataLoaded || !this.isAuthenticated) {
			this.loadData(this.studyIds, this.reportIds)
		} else {
			isUserDataLoadedUnwatch = this.$watch('isUserDataLoaded', () => {
				isUserDataLoadedUnwatch()
				isUserDataLoadedUnwatch = undefined
				this.loadData(this.studyIds, this.reportIds)
			})
		}
	},
	beforeDestroy() {
		wsData.unsubscribeByCallback(this.refreshStudy)
		// Study/Report cleanup
		delete window.isShowingStudyOrReport
		delete window.onAddToViewer
		eventBus.off('openViewerReportComment', this.onOpenReportCommentAction)
		eventBus.off(eventBus.type.REPORT_COMPLETED, this.onReportCompleted)
		eventBus.off(eventBus.type.CLOSE)
		keyboard.startListening()
		// Electron cleanup
		if (omniDesktop.isConnected) {
			omniDesktop.off('addToViewer', this.onAddToViewer)
			omniDesktop.off('resetViewer', this.onResetViewer)
		}
		// Viewer cleanup
		window.removeEventListener('beforeunload', this.onViewerClosed)
	},
	methods: {
		...mapActions([
			'getStudyViewerVmAsync',
			'getStudyPartnerViewerVmAsync',
			'getReportViewerVmAsync',
			'createAnnotationRenderingAsync',
			'createMprAnnotationRenderingAsync',
			'getHangingProtocols',
			'getUserViewerSettings',
		]),
		async refreshStudy(studyId) {
			let r = await api.viewer.getStudy({ ids: [studyId] }, false)
			if (r.studies && r.studies.length) this.$store.commit('REFRESH_STUDY', r.studies[0])
		},
		setupPrimaryToolbarConfig() {
			const primaryToolbarConfig = {}
			if (this.isPartnerViewer && !this.isAuthenticated) {
				primaryToolbarConfig['Sign In'] = {
					group: 'right',
					class: 'label-right',
					label: 'Sign In',
					icon: 'account-circle',
					action: () => {
						this.$router.replace({
							name: 'studies-viewer',
							params: this.$route.params,
							query: { ...this.$route.query, clinicCode: this.clinicCode },
						})
					},
				}
			}
			this.primaryToolbarConfig = primaryToolbarConfig
		},
		onDropStudyReport(data) {
			const studyIds = data.studyId ? [data.studyId] : null
			const reportIds = data.reportId ? [data.reportId] : null
			this.loadData(studyIds, reportIds, { fillCanvases: false })
		},
		async loadData(studyIds, reportIds, opts) {
			const hasStudies = studyIds && studyIds.length > 0
			const hasReports = reportIds && reportIds.length > 0
			opts = opts || {
				initialSeriesId: this.initialSeriesId,
				initialSeriesIds: this.initialSeriesIds,
				initialImageId: this.initialImageId,
			}
			// Allow opening the route with no data
			if (!hasStudies && !hasReports) {
				// Doesn't actually have an error, but this clears the text to make a "blank" viewer window.
				this.$store.commit('SET_VIEWER_HIDE_LOADING_TEXT')
				return
			}
			const requests = []
			// Hanging protocol request
			if (this.allowHangingProtocol) {
				requests.push(this.getHangingProtocols())
			}
			if (this.$route.query.layout) {
				const [columns, rows] = this.$route.query.layout.split('x')
				this.$store.commit('SET_CANVAS_LAYOUT', {
					columns: Number(columns),
					numCanvases: Number(columns) * Number(rows),
				})
			}
			// Study requests
			if (hasStudies) {
				const getStudyViewerVm = this.isPartnerViewer ? this.getStudyPartnerViewerVmAsync : this.getStudyViewerVmAsync
				requests.push(
					getStudyViewerVm({
						ids: studyIds,
						clinicCode: this.clinicCode,
						...opts,
					})
				)
			}
			// Report requests
			if (hasReports) {
				requests.push(this.refreshReport())
				reportIds.forEach(id =>
					requests.push(
						this.getReportViewerVmAsync({
							id,
							clinicCode: this.clinicCode,
							...opts,
						})
					)
				)
			}
			// Wait for all requests
			await Promise.all(requests)
			if (this.allowHangingProtocol) {
				// Currently just pulls last-used hanging protocol
				await this.getUserViewerSettings()
			}
		},
		async promptToAddStudiesToClinic() {
			// If user is logged in, using the full viewer, has a source clinic code,
			// and has studies not found, then offer to add the studies to their clinic
			if (this.isPartnerViewer || !this.isAuthenticated || !this.clinicCode) return
			if (!this.studiesNotFound || !this.studiesNotFound.length) return
			const isConfirmed = await confirmAddingStudies()
			if (isConfirmed) {
				this.addStudiesAndReload()
			} else {
				this.goToPartnerViewer()
			}

			function confirmAddingStudies() {
				const message = `In order to enable all features, such as emailing images, this study must be added to your clinic. Would you like to do that?`
				return showConfirm(message, {
					confirmText: 'Yes, add the study to my clinic',
					cancelText: 'No, view with limited features',
				})
			}
		},
		async addStudiesAndReload() {
			await this.$api.viewer.addStudiesToClinic(
				this.studiesNotFound.map(id => ({
					sourceClinicCode: this.clinicCode,
					studyId: id,
				}))
			)
			// Reload after studies are added
			window.location.reload()
		},
		goToPartnerViewer() {
			// If user opts for limited features, redirect to partner viewer
			this.$router.replace(
				{
					name: 'partner-study-viewer',
					params: { ...this.$route.params, clinicCode: this.clinicCode },
					query: { optedForPartnerViewer: true },
				},
				// Reload once routing is done
				() => window.location.reload()
			)
		},
		/**
		 * assumes `query` to be an object with the folloing optional values
		 * { studyId, reportId, studyIds, reportIds, sid, initialSeriesIds = [], imageId, layout }
		 * assumes studyIds & reportIds, if included, is a comma-delimited string of unique study/report Ids
		 * matching the output of arrayToList
		 */
		onAddToViewer(event, query) {
			const { studyId, reportId, initialSeriesId } = query
			query.studyIds = arrayToList([...listToArray(query.studyIds), ...this.studyIds, studyId])
			query.reportIds = arrayToList([...listToArray(query.reportIds), ...this.reportIds, reportId])
			if (initialSeriesId) query.sid = initialSeriesId
			this.$router.replace({
				...this.$route,
				query,
			})
		},
		onResetViewer() {
			this.$router.replace({ name: 'viewer' })
		},
		onViewerClosed() {
			eventBus.broadcast(eventBus.type.VIEWER_CLOSED, {
				studyIds: this.studyIds,
				reportIds: this.reportIds,
			})
		},
		onReportCompleted(reportId) {
			if (!reportId) return
			if (this.studyIds && this.studyIds.length > 0) return
			if (!this.reportIds || this.reportIds.length > 1 || this.reportIds[0] !== reportId) return
			window.close()
		},
		onExternalWindowClosed({ routeName, routePath }) {
			// If external windows close with one of a few approved routes, we'll save
			// the route path as a fallback on close
			const fallbackRoutes = ['studies', 'teleconsultations', 'teleconsultation']
			if (fallbackRoutes.includes(routeName)) {
				this.fallbackRoutePath = routePath
			}
		},
		onOpenReportCommentAction() {
			if (this.activeReportId && this.isImageCommentAllowed && !this.isImageCommentOpen) {
				this.isImageCommentOpen = true
			}
		},
		async updateImageComment() {
			if (!this.activeReportId || this.isAddingComment || this.isRefreshingReport) return
			if (this.mprActive) return this.addMprImageComment()
			try {
				if (!this.imageComment) {
					this.isAddingComment = true
					// add blank comment to make image a "key image" on the report
					let data = {
						imageId: this.activeImage.imageId,
						seriesId: this.activeSeries.seriesId,
						htmlValue: '',
						textValue: '',
						reportImageCommentId: undefined,
					}
					await reportService.saveImageComment(this.activeReportId, data)
				}
				eventBus.broadcast(eventBus.type.REPORT_IMAGE_COMMENT, this.activeReportId)
				await this.refreshReport()
				this.isImageCommentOpen = true
			} finally {
				this.isAddingComment = false
			}
		},
		async addMprImageComment() {
			const warning =
				'A rendering of the active MPR view will be added as a new image to the study as well as the report.  Are you sure you want to continue?'
			if (!(await showConfirm(warning))) return
			try {
				this.isAddingComment = true
				const newSeries = await this.createMprAnnotationRenderingAsync({
					reportId: this.activeReportId,
					showNotification: false,
				})
				// add blank comment to make image a "key image" on the report
				let data = {
					imageId: newSeries.images[0].imageId,
					seriesId: newSeries.seriesId,
					htmlValue: '',
					textValue: '',
					reportImageCommentId: undefined,
				}
				await reportService.saveImageComment(this.activeReportId, data)
				eventBus.broadcast(eventBus.type.REPORT_IMAGE_COMMENT, this.activeReportId)
				await this.refreshReport()
				this.$store.dispatch('addNotification', {
					message:
						'<b>The new image was added to the study and report.</b><br><br>To add a comment to the image, switch to the report window or exit MPR.',
					notificationType: 'success',
				})
			} finally {
				this.isAddingComment = false
			}
		},
		async refreshReport() {
			if (!this.activeReportId) {
				this.report = null
				return
			}
			this.isRefreshingReport = true
			if (!this.isAddingComment) this.isImageCommentOpen = false
			this.report = await reportService.getReportImageComments(this.activeReportId)
			this.isRefreshingReport = false
		},
		async downloadStudies() {
			await openViewerStudyDownloadModal({ clinicCode: this.clinicCode, studies: this.studies })
		},
		async burnInAnnotations(callback) {
			try {
				const burnInAction = this.mprActive
					? this.createMprAnnotationRenderingAsync
					: this.createAnnotationRenderingAsync
				await burnInAction(this.activeReportId || undefined)
			} finally {
				callback()
			}
		},
		async goBack() {
			const windowOpeners = ['worklist', 'report-detail']
			// Fallback logic
			const fallback = () => {
				let location = '/'
				if (this.$store.getters.isCommunityUser) {
					location = '/teleconsultation-image-uploads'
				} else if (this.fallbackRoutePath) {
					location = this.fallbackRoutePath
				} else if (this.fromRoutePath) {
					location = this.fromRoutePath
				}
				this.$router.push(location)
			}
			try {
				const shouldAttemptContactingParent =
					this.isNewWindow && window.opener && windowOpeners.includes(window.opener.name)
				if (omniDesktop.isConnected && !(await omniDesktop.request('isWorklistWindow'))) {
					this.$router.replace('/viewer/')
					omniDesktop.request('clearViewer')
				} else if (shouldAttemptContactingParent) {
					// Clear the window and focus the primary window
					this.$router.replace('/viewer/')
					// This is a kind of hacky way of returning focus to the primary window.
					// Will focus the oldest worklist tab, can't help it.
					const worklist = window.open('', window.opener.name).focus()
				} else if (this.fromRoutePath) {
					this.$router.push(this.fromRoutePath)
				} else if (window.history.length > 1) {
					this.$router.go(-1)
				} else {
					fallback()
				}
			} catch (err) {
				// Attempting to access window.opener.name may throw a cross-origin exception in Chrome/Edge
				fallback()
			}
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';
.added-to-report .icon {
	color: var(--icon-success);
}
</style>
