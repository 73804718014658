<template>
	<section style="padding: 16px;">
		<div class="preview-image-container" style="margin-bottom: 24px;">
			<img v-if="imageUrl" :src="imageUrl" />
		</div>

		<p class="is-success" style="margin-bottom: 16px;">
			<svg-icon icon="check-circle" fixed />
			This image has been added as a key image on the report!
		</p>
		<h4>Comment: <small>(optional)</small></h4>
    <wysiwyg-editor-tiny
      ref="editor"
      :read-only="false"
      :value="form.htmlValue"
      @input="updateTextValue"
    />

		<button class="btn btn-primary" :disabled="isLoading || hasNotAddedComment" @click="updateComment">
			{{ imageComment.textValue ? 'Update' : 'Create' }} Comment
		</button>
		<div style="margin-top: 24px;">
			<button class="btn btn-default btn-delete" :disabled="isLoading" @click="deleteComment">
				Remove image from report
			</button>
		</div>
	</section>
</template>

<script>
import WysiwygEditorTiny from "@components/WysiwygEditorTiny.vue"
import { eventBus } from '@services/eventBus'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import reportService from '@services/reportService'

export default {
	name: 'ImageCommentForm',
	components: {
    WysiwygEditorTiny,
	},
	props: {
		reportId: {
			type: String,
			required: true,
		},
		imageId: {
			type: String,
			default: undefined,
			required: false,
		},
		seriesId: {
			type: String,
			default: undefined,
			required: false,
		},
		previewImageUrl: {
			type: String,
			required: true,
		},
		imageComment: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			isLoading: false,
			form: {
				reportImageCommentId: null,
				htmlValue: null,
				textValue: null,
			},
		}
	},
	computed: {
		imageUrl() {
			const previewUrl = this.previewImageUrl
			const isEmpty = previewUrl.length < 1
			const hasNulls = previewUrl.indexOf('null') > -1
			const hasUndefined = previewUrl.indexOf('undefined') > -1
			const canDisplay = !isEmpty && !hasNulls && !hasUndefined
			return canDisplay ? this.previewImageUrl : ''
		},
		hasNotAddedComment() {
			if (!this.imageComment) return true
			return !this.form.textValue && !this.imageComment.textValue
		},
	},
	created() {
		this.form.reportImageCommentId = this.imageComment.reportImageId
		this.form.textValue = this.imageComment.textValue
		this.form.htmlValue = this.imageComment.htmlValue
	},
	methods: {
		async updateComment() {
			if (this.isLoading) return
			this.isLoading = true
			const confirmUnload = e => {
				const warning = 'The image comment has not finished saving.  Are you sure you want to leave?'
				e.returnValue = warning
				return warning
			}
			window.addEventListener('beforeunload', confirmUnload)
			try {
				let data = {
					imageId: this.imageId,
					seriesId: this.seriesId,
					htmlValue: this.form.htmlValue,
					textValue: this.form.textValue,
					reportImageCommentId: this.form.reportImageCommentId,
				}
				await reportService.saveImageComment(this.reportId, data)
				this.$store.dispatch('addNotification', {
					message: 'Updated report image comment!',
					notificationType: 'success',
				})

				this.$emit('update-comment')
				eventBus.broadcast('report-image-comment', this.reportId)
			} finally {
				window.removeEventListener('beforeunload', confirmUnload)
				this.isLoading = false
			}
		},
		async deleteComment() {
			if (this.isLoading) return
			if (!(await showConfirm('Are you sure you want to remove this image from the report?'))) return
			this.isLoading = true
			try {
				await reportService.deleteImageComment(this.reportId, this.imageId)
				this.$emit('update-comment')
				eventBus.broadcast('report-image-comment', this.reportId)
			} finally {
				this.isLoading = false
			}
		},
		updateTextValue(htmlValue, textValue ) {
			if (textValue) {
				this.form.textValue = textValue
			}
      this.form.htmlValue = htmlValue
		},
	},
}
</script>

<style lang="scss" scoped>
.preview-image-container {
	width: 100%;
	height: 150px;
	background: black;

	img {
		display: block;
		height: 100%;
		margin: 0 auto;
	}
}
.btn-delete {
	&:hover {
		background: var(--button-danger-bg);
		color: var(--button-danger-color);
		border-color: transparent;
	}
}
</style>
