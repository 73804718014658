<template>
	<modal-wrapper @close="close">
		<div class="download-container">
			<!--Header-->
			<dlg-header title="Download Studies" @close="close" />
			<!--Content-->
			<div class="download-content">
				<!--Exclude instruction-->
				<p style="margin: 0.5rem 1rem">
					<svg-icon icon="info-circle" class="is-info" style="margin-right:0.25rem" fixed />
					<!-- eslint-disable-next-line vue/no-v-html -->
					<span v-html="instruction"></span>
				</p>
				<!--Study thumbnails & attachments-->
				<div v-for="{ studyId, imageData, patientName, studyDateTime, isArchived } in studies" :key="studyId">
					<!--Study header-->
					<div class="download-study-header" :class="{ 'is-excluded': isStudyExcluded(studyId) || !isArchived }">
						<h4 :title="studyId">{{ patientName }} on {{ studyDateTime | localizeDate }}</h4>
						<checkbox :value="isStudyExcluded(studyId)" :disabled="!isArchived" @input="toggleStudy(studyId)">
							Exclude
						</checkbox>
					</div>
					<p v-if="!isArchived" style="font-size: 0.9em; padding: 0.5rem 1rem;">
						<svg-icon icon="exclamation-triangle" fixed class="is-warning" />
						This study may be downloaded once it has transferred to the cloud.
					</p>
					<div class="download-study" :class="{ 'is-excluded': isStudyExcluded(studyId) || !isArchived }">
						<!--Thumbnail list-->
						<div v-if="imageData.thumbnails.length">
							<h4 style="margin: 0.5rem 1rem 0 1rem">Thumbnails</h4>
							<ol class="thumbnail-list">
								<li
									v-for="(thumbnail, i) in imageData.thumbnails"
									:key="i"
									class="thumbnail-list-item"
									@click.prevent="toggleSeries(thumbnail.seriesId)"
								>
									<div
										class="thumbnail"
										alt="Thumbnail preview of first image in series"
										:style="{
											'background-image': `url(${findThumbnailUrl(thumbnail)})`,
										}"
									>
										<div v-if="isStudyExcluded(studyId) || isSeriesExcluded(thumbnail.seriesId)" class="excluded">
											Excluded
										</div>
									</div>
									<div class="thumbnail-label">
										<strong v-if="thumbnail.seriesNumber">{{ thumbnail.seriesNumber }}</strong>
										<span v-if="thumbnail.simpleName" class="thumbnail-name">{{ thumbnail.simpleName }}</span>
										<p v-if="thumbnail.numberOfImages > 1" class="thumbnail-images">
											({{ thumbnail.numberOfImages }} Images)
										</p>
									</div>
								</li>
							</ol>
						</div>
						<!--Attachment list-->
						<attachment-list
							v-if="imageData.attachments.length"
							class="attachment-list"
							style="margin:0.5rem 1rem 1rem 1rem"
							:attachments="imageData.attachments"
							:series-to-exclude="seriesToExclude"
							:open-on-click="false"
							@select="toggleSeries"
						/>
					</div>
				</div>
			</div>
			<!--Footer-->
			<div class="download-footer">
				<!--Download options-->
				<template v-if="optionsVisible">
					<div class="download-options">
						<div class="download-option">
							<span class="download-option-label">Image Type:</span>
							<div class="download-selections">
								<span class="radio">
									<label for="dicom">
										<input id="dicom" v-model="downloadType" type="radio" value="1" />
										DICOM
									</label>
								</span>
								<span class="radio">
									<label for="jpeg">
										<input id="jpeg" v-model="downloadType" type="radio" value="0" />
										JPEG
									</label>
								</span>
							</div>
						</div>
						<div class="download-option">
							<span class="download-option-label">Folder Option:</span>
							<div class="download-selections">
								<span class="radio">
									<label for="single">
										<input id="single" v-model="folderOption" type="radio" value="0" />
										Single
									</label>
								</span>
								<span class="radio">
									<label for="studySeries">
										<input id="studySeries" v-model="folderOption" type="radio" value="1" />
										Study/Series
									</label>
								</span>
								<span class="radio">
									<label for="patientDate">
										<input id="patientDate" v-model="folderOption" type="radio" value="2" />
										Patient & Date
									</label>
								</span>
							</div>
						</div>
					</div>
				</template>
				<div class="download-action">
					<!--Download button-->
					<button
						class="btn btn-primary"
						:class="{ disabled: downloadDisabled }"
						style="margin:0.5rem 0"
						@click="download"
					>
						<svg-icon icon="cloud-download" fixed />
						Download
					</button>
					<div v-if="!optionsVisible" class="download-options-tip" @click="optionsVisible = !optionsVisible">
						<span v-html="downloadOptionsTip"></span>
						<span style="font-size:8px;margin-left:8px">&#9650;</span></div
					>
				</div>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper'
import DlgHeader from '@components/DlgHeader'
import AttachmentList from '@components/AttachmentList.vue'
import Checkbox from '@components/Checkbox.vue'
import { findThumbnailUrl } from '@utils/urlUtility'
import { dlg } from '@utils/dlgUtils'
import { mapState } from 'vuex'

const ViewerStudyDownloadModal = {
	name: 'ViewerStudyDownloadModal',
	components: {
		ModalWrapper,
		DlgHeader,
		AttachmentList,
		Checkbox,
	},
	props: {
		clinicCode: {
			type: String,
			default: undefined,
			required: false,
		},
	},
	data() {
		return {
			studiesToExclude: [],
			seriesToExclude: [],
			downloadType: 1,
			folderOption: 0,
			optionsVisible: false,
		}
	},
	computed: {
		...mapState({
			studies: state => state.viewer.studies,
			claims: state => state.auth.claims,
		}),
		hasThumbnails() {
			return this.studies.some(s => s.imageData.thumbnails.length)
		},
		hasAttachments() {
			return this.studies.some(s => s.imageData.attachments.length)
		},
		instruction() {
			let instruction = 'Click on an '
			if (this.hasThumbnails) instruction += 'image '
			if (this.hasThumbnails && this.hasAttachments) instruction += 'or '
			if (this.hasAttachments) instruction += 'attachment'
			instruction += ' to <strong>exclude</strong> from download'
			return instruction
		},
		downloadOptionsTip() {
			let tip = 'Downloading '
			tip += `<em>${this.downloadType === 1 ? 'DICOM' : 'JPEG'}</em> images in `
			switch (this.folderOption) {
				case 1:
					tip += '<em>study/series</em> folders'
					break
				case 2:
					tip += '<em>patient/date</em> folders'
					break
				default:
					tip += 'a <em>single</em> folder'
					break
			}
			return tip
		},
		studiesGroupedByReportId() {
			return this.studies.reduce((result, s) => {
				const reportId = s.reportId || 'empty'
				result[reportId] = result[reportId] || []
				result[reportId].push(s)
			}, {})
		},
		downloadReportStudies() {
			const reportStudies = {}
			this.studies.forEach(study => {
				if (this.isStudyExcluded(study.studyId) || !study.isArchived) return
				// Get a flattened list of seriesIds
				const seriesIds = [...study.imageData.thumbnails, ...study.imageData.attachments].map(s => s.seriesId)
				// Get the included/excluded seriesIds
				const includeSeriesIds = seriesIds.filter(s => !this.isSeriesExcluded(s))
				const excludeSeriesIds = seriesIds.filter(this.isSeriesExcluded)
				// All series could be excluded
				if (includeSeriesIds.length) {
					const reportId = study.reportId || 'empty'
					reportStudies[reportId] = reportStudies[reportId] || []
					reportStudies[reportId].push(study.studyId)
				}
			})
			return reportStudies
		},
		downloadDisabled() {
			return !Object.entries(this.downloadReportStudies).length
		},
	},
	mounted() {
		this.folderOption = this.studies && this.studies.length > 0 ? 1 : 0
	},
	methods: {
		findThumbnailUrl,
		toggleSeries(seriesId) {
			const seriesToExcludeIndex = this.seriesToExclude.indexOf(seriesId)
			if (seriesToExcludeIndex >= 0) {
				this.seriesToExclude.splice(seriesToExcludeIndex, 1)
			} else {
				this.seriesToExclude.push(seriesId)
			}
		},
		isSeriesExcluded(seriesId) {
			return this.seriesToExclude.includes(seriesId)
		},
		toggleStudy(studyId) {
			const studyToExcludeIndex = this.studiesToExclude.indexOf(studyId)
			if (studyToExcludeIndex >= 0) {
				this.studiesToExclude.splice(studyToExcludeIndex, 1)
			} else {
				this.studiesToExclude.push(studyId)
			}
		},
		isStudyExcluded(studyId) {
			return this.studiesToExclude.includes(studyId)
		},
		download() {
			let clinicCode = this.clinicCode || this.claims.activeClinicCode
			const { downloadType, folderOption } = this
			const query = {
				clinicCode,
				downloadType,
				folderOption,
				reportIds: [],
				studyIds: [],
				excludeStudyIds: this.studiesToExclude,
				excludeSeriesIds: this.seriesToExclude,
			}
			Object.entries(this.downloadReportStudies).forEach(([reportId, studyIds]) => {
				if (reportId === 'empty') {
					query.studyIds = query.studyIds.concat(studyIds)
				} else {
					query.reportIds.push(reportId)
				}
			})
			this.$api.file.downloadImages(query)
			this.close()
		},
		close() {
			dlg.close(this)
		},
	},
}

export default ViewerStudyDownloadModal

export function openViewerStudyDownloadModal({ clinicCode, studies }) {
	dlg.open(ViewerStudyDownloadModal, { clinicCode, studies })
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.download-container {
	display: flex;
	flex-direction: column;
	background: var(--primary-bg);
	height: 100vh;
	width: 100vw;
	max-width: 100vw;
	.dlg-header {
		background: var(--secondary-bg);
	}
	@media (min-width: $mqSmall) {
		width: 540px;
		max-width: 90vw;
		height: auto;
		max-height: 85vh;
		.download-options-tip {
			font-size: 1rem;
		}
	}
}

.download-content {
	flex-grow: 1;
	background: var(--primary-bg);
	border-top: 1px solid var(--divider);
	overflow-y: auto;
	.download-study.is-excluded {
		opacity: 0.25;
		pointer-events: none;
		::v-deep .attachment-list a {
			text-decoration: line-through;
		}
	}
	.download-study-header {
		display: flex;
		justify-content: space-between;
		background: var(--tertiary-bg);
		color: var(--secondary-label);
		padding: 0.5rem 1rem;
		&.is-excluded {
			h4 {
				text-decoration: line-through;
			}
		}
		.checkbox-label {
			margin: 0;
		}
	}
	.thumbnail-list {
		list-style: none;
		margin: 0 6px;
		.thumbnail-list-item {
			cursor: pointer;
			list-style: none;
			display: inline-block;
			width: 125px;
			margin: 10px;
			vertical-align: top;
		}
	}
	.thumbnail {
		display: flex;
		position: relative;
		align-items: center;
		justify-content: center;
		height: 125px;
		background-size: contain;
		background-color: black;
		background-position: center;
		background-repeat: no-repeat;
		user-select: none;
		&:hover {
			opacity: 0.8;
		}
		.excluded {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0.9;
			font-weight: 500;
			font-style: italic;
			background: var(--primary-bg);
		}
	}
	.thumbnail-label,
	strong,
	.thumbnail-images {
		font-size: 0.9em;
		text-align: center;
		word-break: break-word;
		hyphens: auto;
	}
	strong + .thumbnail-name::before {
		content: ': ';
	}
}

.download-footer {
	flex-shrink: 0;
	background: var(--secondary-bg);
	border-top: 1px solid var(--divider);
	padding: 0.5rem 1rem;
	.download-options {
		.download-option {
			display: flex;
			flex-grow: 1;
			margin: 0.25rem 0 0.5rem 0;
			.download-option-label {
				color: var(--tertiary-label);
				font-weight: 600;
				flex-shrink: 0;
				flex-basis: 110px;
			}
			.download-selections {
				display: flex;
				flex-direction: column;
				.radio {
					flex-shrink: 0;
					display: flex;
					align-items: center;
					margin-left: 0.75rem;
				}
			}
		}
		@media (min-width: 465px) {
			.download-option {
				align-items: center;
				.download-selections {
					flex-direction: row;
				}
			}
		}
	}
	.download-action {
		display: flex;
		justify-content: space-between;
		align-items: center;
		button: {
			margin: 0.5rem 0;
		}
		.download-options-tip {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			text-align: right;
			color: var(--link);
			font-size: 0.9rem;
			margin: 0 0 0 1rem;
			cursor: pointer;
			&:hover {
				opacity: 0.75;
			}
		}
		@media (min-width: $mqSmall) {
			.download-options-tip {
				font-size: 1rem;
			}
		}
	}
}
</style>
